import React, {useState, useRef} from "react";
import { Grid, Box, Paper, InputAdornment, Typography, Stack, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, TextField, Button } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import VerifiedIcon from '@mui/icons-material/Verified';
import { ModalConfirmacion } from "./ModalConfirmacion";
import { globals } from "../../data/Globals";
import { useNavigate } from "react-router-dom";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { UploadToBlob } from "../blob/blob_storage";
import { MD5 } from "crypto-js";
import ReplyIcon from '@mui/icons-material/Reply';
import { ModalUndo } from "./ModalUndo";

export const FormRecogidoGestor = ({recogida}) => {
    const navigate = useNavigate();

    const [valueToneladas, setToneladas] = useState()
    const [errorToneladas, setErrorToneldas] = useState(false)
    const [msgToneladas, setMsgToneladas] = useState("")
    const [valueObservaciones, setObservaciones] = useState("")

    const [showConfirmationPopup, setConfirmationPopup] = useState(false);

    const [showUndoPopup, setUndoPopup] = useState(false)

    const openUndoPopup = () =>{
        setUndoPopup(true)
    }

    const AlbaranRef = useRef(null);
    const handleAlbaranUpdate = (event) => {
        async function updateAlbaran(_albName){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'Albaran',
                    'gestor':recogida.GESTOR,
                    'productor':recogida.PRODUCTOR,
                    'albaran':_albName,
                    'id':recogida.ID_RECOGIDA
                })
            })

            navigate(0)
        }

        // const token = MD5(new Date().getTime()).toString()
        const token = MD5(recogida.ID_RECOGIDA + recogida.GESTOR + recogida.PRODUCTOR + event.target.files[0].name).toString()
        const albaranName = event.target.files[0].name.replace(".pdf",token+".pdf")
        UploadToBlob(event.target.files[0], albaranName);
        updateAlbaran(albaranName)
    }

    const DifaRef = useRef(null);
    const handleDifaUpdate = (event) => {
        async function updateDifa(_difaName){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'DIFA',
                    'gestor':recogida.GESTOR,
                    'productor':recogida.PRODUCTOR,
                    'difa':_difaName,
                    'id':recogida.ID_RECOGIDA
                })
            })

            navigate(0)
        }

        // const token = MD5(new Date().getTime()).toString()
        const token = MD5(recogida.ID_RECOGIDA + recogida.GESTOR + recogida.PRODUCTOR + event.target.files[0].name).toString()
        const difaName = event.target.files[0].name.replace(".pdf",token+".pdf")
        UploadToBlob(event.target.files[0], difaName);
        updateDifa(difaName)
    }

    const openConfirmationPopup = ()=>{
        let error = false

        if(!valueToneladas){
            error = true
            setErrorToneldas(true)
            setMsgToneladas("Campo obligatorio")
        }
        if(recogida.TON_DISP && parseFloat(valueToneladas.replace(",",".")) > parseFloat(recogida.TON_DISP.replace(",","."))){
            error = true
            setErrorToneldas(true)
            setMsgToneladas("Tonelada máxima superada ("+ recogida.TON_DISP +"tn disponibles)")
        }

        if(!error){
            setConfirmationPopup(true)
        }        
    }

    return(
        <Stack fullWidth mt={3} mb={3}>
            <Grid container display="flex" justifyContent="space-between" mb={2}>
                <Grid item xs={12}>
                    <Paper elevation={3}>
                        <Grid container display="flex" justifyContent="space-between" alignItems="center" pt={2} pb={2} mb={2} rowGap={2}>
                            <Grid item xs={3}>
                                <Typography fontSize={24} m={2}>
                                    <b>Datos finales:</b> 
                                </Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    fullWidth
                                    label="Toneladas reales" 
                                    value={valueToneladas}
                                    error={errorToneladas}
                                    helperText={msgToneladas}
                                    onChange={(event)=>{
                                        setToneladas(event.target.value);
                                        setErrorToneldas(false);
                                        setMsgToneladas("");
                                    }}
                                    required
                                    InputProps={{
                                        endAdornment:<InputAdornment position="end">Tn</InputAdornment>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} />
                            <Grid item xs={2.5}>
                                <Button variant="contained" endIcon={<VerifiedIcon/>} size="large" onClick={openConfirmationPopup}>
                                    Finalizar gestión
                                </Button>
                            </Grid>
                            <Grid item xs={3}/>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Observaciones"
                                    multiline
                                    rows={2}
                                    value={valueObservaciones}
                                    onChange={(event)=>{
                                        setObservaciones(event.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}/>
                            <Grid item xs={2.5}/>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container display="flex" justifyContent="space-between" >
                <Grid item xs={3}>
                <Paper elevation={3}>
                    <Grid container pr={2}>
                        <Grid item xs={12}>
                            <Typography fontSize={24} m={2}>
                                <b>Documentos Adjuntos:</b> 
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" size="small" component="a" href={recogida.CONTRATO} target="_blank" rel="noopener noreferrer">
                                            <FindInPageIcon fontSize="small"/>
                                        </IconButton>
                                    }
                                    
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                        sx={{overflow:"hidden"}}
                                        primary="Contrato"
                                        secondary={recogida.CONTRATO.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.CONTRATO.substr(-36),".pdf")}
                                    />    
                                </ListItem>
                                {globals.LER_Peligrosidad[recogida.DESC_LER] ?
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" size="small" component="a" href={recogida.NOTIFICACION} target="_blank" rel="noopener noreferrer">
                                            <FindInPageIcon fontSize="small"/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                    sx={{overflow:"hidden"}}
                                        primary={<Stack><Typography>
                                                NP o FA
                                                </Typography>
                                                <Typography color="secondary" fontWeight={900}>
                                                {"(" + recogida.TON_DISP + "Tn disponibles)"} 
                                                </Typography></Stack>}
                                        secondary={recogida.NOTIFICACION.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.NOTIFICACION.substr(-36),".pdf")}
                                    />    
                                </ListItem>
                                : <></>}
                                <ListItem
                                    secondaryAction={
                                        <Grid container>
                                        <Grid item sx={6}>
                                        <IconButton edge="end" size="small" onClick={()=>(DifaRef.current.click())}>
                                            <input hidden accept=".pdf" ref={DifaRef} type="file" onChange={(event)=>{handleDifaUpdate(event)}}/>
                                            <UploadFileIcon fontSize="small"/>
                                        </IconButton>
                                        </Grid>
                                        <Grid item sx={6}>
                                        <IconButton edge="end" size="small" component="a" href={recogida.DIFA} target="_blank" rel="noopener noreferrer">
                                            <FindInPageIcon fontSize="small"/>
                                        </IconButton>
                                        </Grid>
                                        </Grid>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                        sx={{overflow:"hidden"}}
                                        primary="Ficha de DI o FS"
                                        secondary={recogida.DIFA.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.DIFA.substr(-36),".pdf")}
                                    />    
                                </ListItem>
                                {recogida.ALBARAN !== "https://bsekologica1.blob.core.windows.net/ekogest/ekogest/" ?
                                <ListItem
                                    secondaryAction={
                                        <Grid container>
                                        <Grid item sx={6}>
                                        <IconButton edge="end" size="small" onClick={()=>(AlbaranRef.current.click())}>
                                            <input hidden accept=".pdf" ref={AlbaranRef} type="file" onChange={(event)=>{handleAlbaranUpdate(event)}}/>
                                            <UploadFileIcon fontSize="small"/>
                                        </IconButton>
                                        </Grid>
                                        <Grid item sx={6}>
                                        <IconButton edge="end" size="small" component="a" href={recogida.ALBARAN} target="_blank" rel="noopener noreferrer">
                                            <FindInPageIcon fontSize="small"/>
                                        </IconButton>
                                        </Grid>
                                        </Grid>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                        sx={{overflow:"hidden"}}
                                        primary="Albarán"
                                        secondary={recogida.ALBARAN.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.ALBARAN.substr(-36),".pdf")}
                                    />    
                                </ListItem>
                                :
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" size="large" onClick={()=>(AlbaranRef.current.click())}>
                                            <UploadFileIcon fontSize="large"/>
                                            <input hidden accept=".pdf" ref={AlbaranRef} type="file" onChange={(event)=>{handleAlbaranUpdate(event)}}/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                        sx={{overflow:"hidden"}}
                                        primary="Albarán"
                                        secondary="Subir albaran"
                                    />    
                                </ListItem>
                                }
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
                </Grid>
                <Grid item xs={8.8} display="flex" flexDirection="column" justifyContent="space-between">
                    <Stack fullWidth>
                    <Paper elevation={3}>
                        <Grid Grid container pr={2} pb={2.15} pl={2} pt={2} display="flex" justifyContent="center">
                            <Grid item xs={12} mb={2}>
                                <Typography fontSize={24}>
                                    <b>Datos Adicionales:</b> 
                                </Typography>
                            </Grid>
                            <Grid item xs={11.5} mb={2}>
                                <Grid container rowGap={3} display="flex" justifyContent="space-between">                                    
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Nº DI"
                                        variant="outlined"
                                        defaultValue={recogida.COD_DIFA}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Transportista"
                                        variant="outlined"
                                        defaultValue={recogida.TRANSPORTISTA + " - ("+ recogida.MATRICULA +")"}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Tipo de contenedores"
                                        variant="outlined"
                                        defaultValue={recogida.TIPO_CONTENEDOR}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>  
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Precio"
                                        variant="outlined"
                                        defaultValue={recogida.PRECIO}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        InputProps={{
                                            endAdornment:<InputAdornment position="end">€/Tn</InputAdornment>,
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Tonelaje Teórico"
                                        variant="outlined"
                                        defaultValue={recogida.TON_TEORICO}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        InputProps={{
                                            endAdornment:<InputAdornment position="end">Tn</InputAdornment>,
                                        }}
                                        inputProps={{
                                            readOnly: true,                                            
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Número de contenedores"
                                        variant="outlined"
                                        defaultValue={recogida.RECOGIDA_CONTENEDOR + " - ("+ recogida.SUBSTITUDION_CONTENEDOR +" a substituir)"}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>                                                  
                                </Grid>
                            </Grid>
                        </Grid>                    
                    </Paper>
                    <Box fullWidth pt={2} display="flex" justifyContent="end" alignItems="end">
                    <Button variant="contained" color="secondary" startIcon={<ReplyIcon/>} display="flex" onClick={openUndoPopup}>
                        Deshacer paso
                    </Button>
                    </Box>
                    </Stack>
                </Grid>
            </Grid>
            <ModalConfirmacion open={showConfirmationPopup} onClose={()=>setConfirmationPopup(false)} id={recogida.ID_RECOGIDA} toneladas={valueToneladas} observ={valueObservaciones}/>
            <ModalUndo open={showUndoPopup} onClose={()=>setUndoPopup(false)} estado={recogida.ESTADO} id={recogida.ID_RECOGIDA}/>
        </Stack>
    )
}