import React, {useRef} from "react";
import { Grid, Paper, InputAdornment, Typography, Stack, IconButton, List, ListItem, ListItemAvatar, Avatar, ListItemText, TextField } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { globals } from "../../data/Globals";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { UploadToBlob } from "../blob/blob_storage";
import { MD5 } from "crypto-js";
import { useNavigate } from "react-router-dom";

export const FormRecogidoProductor = ({recogida}) => {
    const navigate = useNavigate();

    const AlbaranRef = useRef(null);
    const handleAlbaranUpdate = (event) => {
        async function updateAlbaran(_albName){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'Albaran',
                    'gestor':recogida.GESTOR,
                    'productor':recogida.PRODUCTOR,
                    'albaran':_albName,
                    'id':recogida.ID_RECOGIDA
                })
            })

            navigate(0)
        }

        // const token = MD5(new Date().getTime()).toString()
        const token = MD5(recogida.ID_RECOGIDA + recogida.GESTOR + recogida.PRODUCTOR + event.target.files[0].name).toString()
        const albaranName = event.target.files[0].name.replace(".pdf",token+".pdf")
        UploadToBlob(event.target.files[0], albaranName);
        updateAlbaran(albaranName)
    }

    const DifaRef = useRef(null);
    const handleDifaUpdate = (event) => {
        async function updateDifa(_difaName){
            const response = await fetch(`https://ekogest-api.azurewebsites.net/api/BBDD_Api`,{
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': process.env.REACT_APP_API_KEY,
                },
                body: JSON.stringify({
                    'page':'DIFA',
                    'gestor':recogida.GESTOR,
                    'productor':recogida.PRODUCTOR,
                    'difa':_difaName,
                    'id':recogida.ID_RECOGIDA
                })
            })

            navigate(0)
        }

        // const token = MD5(new Date().getTime()).toString()
        const token = MD5(recogida.ID_RECOGIDA + recogida.GESTOR + recogida.PRODUCTOR + event.target.files[0].name).toString()
        const difaName = event.target.files[0].name.replace(".pdf",token+".pdf")
        UploadToBlob(event.target.files[0], difaName);
        updateDifa(difaName)
    }

    return(
        <Stack fullWidth mt={3} mb={3}>
            <Grid container display="flex" justifyContent="space-between" >
                <Grid item xs={3}>
                <Paper elevation={3}>
                    <Grid container pr={2}>
                        <Grid item xs={12}>
                            <Typography fontSize={24} m={2}>
                                <b>Documentos Adjuntos:</b> 
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <List>
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" size="small" component="a" href={recogida.CONTRATO} target="_blank" rel="noopener noreferrer">
                                            <FindInPageIcon fontSize="small"/>
                                        </IconButton>
                                    }
                                    
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                        sx={{overflow:"hidden"}}
                                        primary="Contrato"
                                        secondary={recogida.CONTRATO.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.CONTRATO.substr(-36),".pdf")}
                                    />    
                                </ListItem>
                                {globals.LER_Peligrosidad[recogida.DESC_LER] ?
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" size="small" component="a" href={recogida.NOTIFICACION} target="_blank" rel="noopener noreferrer">
                                            <FindInPageIcon fontSize="small"/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                    sx={{overflow:"hidden"}}
                                        primary={<Stack><Typography>
                                                NP o FA
                                                </Typography>
                                                <Typography color="secondary" fontWeight={900}>
                                                {"(" + recogida.TON_DISP + "Tn disponibles)"} 
                                                </Typography></Stack>}
                                        secondary={recogida.NOTIFICACION.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.NOTIFICACION.substr(-36),".pdf")}
                                    />    
                                </ListItem>
                                : <></>}
                                <ListItem
                                    secondaryAction={
                                        <Grid container>
                                        <Grid item sx={6}>
                                        <IconButton edge="end" size="small" onClick={()=>(DifaRef.current.click())}>
                                            <input hidden accept=".pdf" ref={DifaRef} type="file" onChange={(event)=>{handleDifaUpdate(event)}}/>
                                            <UploadFileIcon fontSize="small"/>
                                        </IconButton>
                                        </Grid>
                                        <Grid item sx={6}>
                                        <IconButton edge="end" size="small" component="a" href={recogida.DIFA} target="_blank" rel="noopener noreferrer">
                                            <FindInPageIcon fontSize="small"/>
                                        </IconButton>
                                        </Grid>
                                        </Grid>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                        sx={{overflow:"hidden"}}
                                        primary="Ficha de DI o FS"
                                        secondary={recogida.DIFA.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.DIFA.substr(-36),".pdf")}
                                    />    
                                </ListItem>
                                {recogida.ALBARAN !== "https://bsekologica1.blob.core.windows.net/ekogest/ekogest/" ?
                                <ListItem
                                    secondaryAction={
                                        <Grid container>
                                        <Grid item sx={6}>
                                        <IconButton edge="end" size="small" onClick={()=>(AlbaranRef.current.click())}>
                                            <input hidden accept=".pdf" ref={AlbaranRef} type="file" onChange={(event)=>{handleAlbaranUpdate(event)}}/>
                                            <UploadFileIcon fontSize="small"/>
                                        </IconButton>
                                        </Grid>
                                        <Grid item sx={6}>
                                        <IconButton edge="end" size="small" component="a" href={recogida.ALBARAN} target="_blank" rel="noopener noreferrer">
                                            <FindInPageIcon fontSize="small"/>
                                        </IconButton>
                                        </Grid>
                                        </Grid>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                        sx={{overflow:"hidden"}}
                                        primary="Albarán"
                                        secondary={recogida.ALBARAN.replace("https://bsekologica1.blob.core.windows.net/ekogest/ekogest/","").replace(recogida.ALBARAN.substr(-36),".pdf")}
                                    />    
                                </ListItem>
                                :
                                <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" size="large" onClick={()=>(AlbaranRef.current.click())}>
                                            <UploadFileIcon fontSize="large"/>
                                            <input hidden accept=".pdf" ref={AlbaranRef} type="file" onChange={(event)=>{handleAlbaranUpdate(event)}}/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <InsertDriveFileIcon/>
                                        </Avatar>    
                                    </ListItemAvatar>
                                    <ListItemText 
                                        sx={{overflow:"hidden"}}
                                        primary="Albarán"
                                        secondary="Subir albaran"
                                    />    
                                </ListItem>
                                }
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
                </Grid>
                <Grid item xs={8.8}>
                    <Paper elevation={3}>
                        <Grid Grid container pr={2} pb={2.15} pl={2} pt={2} display="flex" justifyContent="center">
                            <Grid item xs={12} mb={2}>
                                <Typography fontSize={24}>
                                    <b>Datos Adicionales:</b> 
                                </Typography>
                            </Grid>
                            <Grid item xs={11.5} mb={2}>
                                <Grid container rowGap={3} display="flex" justifyContent="space-between">                                    
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Nº DI"
                                        variant="outlined"
                                        defaultValue={recogida.COD_DIFA}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Transportista"
                                        variant="outlined"
                                        defaultValue={recogida.TRANSPORTISTA + " - ("+ recogida.MATRICULA +")"}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Tipo de contenedores"
                                        variant="outlined"
                                        defaultValue={recogida.TIPO_CONTENEDOR}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>  
                                    <Grid item xs={3.5}>
                                       
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Tonelaje Terórico"
                                        variant="outlined"
                                        defaultValue={recogida.TON_TEORICO}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        InputProps={{
                                            endAdornment:<InputAdornment position="end">Tn</InputAdornment>,
                                        }}
                                        inputProps={{
                                            readOnly: true,                                            
                                        }}
                                        />
                                    </Grid>
                                    <Grid item xs={3.5}>
                                        <TextField
                                        fullWidth
                                        label="Número de contenedores"
                                        variant="outlined"
                                        defaultValue={recogida.RECOGIDA_CONTENEDOR + " - ("+ recogida.SUBSTITUDION_CONTENEDOR +" a substituir)"}
                                        sx={{
                                            pointerEvents:"none"
                                        }}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                        />
                                    </Grid>                                                  
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Stack>
    )
}